import { graphql, StaticQuery } from "gatsby";
import { Layout } from "../components/utilityComponents/layout";
import React from "react";
import { Information } from "../components/contactPageComponents/information";
import loadable from "@loadable/component";
// @ts-ignore
const Form = loadable(
  () => import("../components/contactPageComponents/form"),
 
);

export default function ContactPage() {
  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          strapiContact {
            id
            openingTimes {
              openTime
              day
              closeTime
            }
            addressDetails {
              address1
              address2
              email
              nip
              phone
              regon
            }
          }
        }
      `}
      render={({ strapiContact: { addressDetails, openingTimes } }) => {
        return (
          <Layout
            darkBackground
            headerChildren={<h1 className="kontakt__header">KONTAKT</h1>}
          >
            <Information
              addressDetails={addressDetails}
              openingTimes={openingTimes}
            />
            <Form  />
          </Layout>
        );
      }}
    />
  );
}
