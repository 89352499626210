import React from "react";
import RestoWhite from "./../../assets/images/restoWhite.svg";
import { Button } from "../utilityComponents/button";
import { useWidth } from "../../hooks/useWidth";
import logo from "../../assets/images/blueVerticalLogo.svg";
import { Container } from "../../helpers/container";
export const Information = ({ addressDetails, openingTimes }: any) => {
  const { isDesktop } = useWidth();
  return (
    <section className="information">
      <Container className="information">
        {isDesktop ? (
          <div className="information__col information__col__withImage">
            <img className="information__image" src={RestoWhite} />
            <p>
              <span>{addressDetails.address1}</span>
            </p>
            <p>
              <span>{addressDetails.address2}</span>
            </p>
            <p>
              <span>{addressDetails.email}</span>
            </p>
            <p>
              Tel. <span>{addressDetails.phone}</span>
            </p>
            <p>
              NIP: <span>{addressDetails.nip}</span>
              <br />
              REGON:<span> {addressDetails.regon}</span>
            </p>
          </div>
        ) : (
          <div className="information__col information__col__withImage information__col__mobile">
            <div>
              <img className="information__image" src={RestoWhite} />
              <p>
                <span>{addressDetails.address1}</span>
              </p>
              <p>
                <span>{addressDetails.address2}</span>
              </p>
              <p>
                <span>{addressDetails.email}</span>
              </p>
              <p>
                Tel. <span>{addressDetails.phone}</span>
              </p>
              <p>
                NIP <span>{addressDetails.nip}</span>
                <br />
                REGON <span> {addressDetails.regon}</span>
              </p>
            </div>
            <img src={logo} />
          </div>
        )}
        <div className="information__col">
          <h4 className="information__header">Rezerwacja</h4>
          <p>
            Zapraszamy do wysłania zapytania, z chęcią przygotujemy dla Państwa
            ofertę – od wynajmu sali konferencyjnej, po kompleksową organizację
            wydarzenia biznesowego lub rodzinnego. Mail do biura:
            restauracja@bckoneser.pl Wychodząc naprzeciw oczekiwaniom naszych
            gości, oferujemy możliwość rezerwacji stolików online. Wystarczy
            wypełnić formularz na naszej stronie i być gotowym na kulinarne
            doznania. Rezerwacja zostanie potwierdzona w ciągu kilku minut, a my
            w tym czasie przygotujemy stolik na Waszą wizytę.
          </p>
          <Button
            style={{ marginTop: !isDesktop ? "15px" : "unset" }}
            label="Rezerwuj"
            size="small"
            onClick={() => (window.location = "/rezerwacja")}
            type="primary"
          />
        </div>
        <div className="information__col">
          <h4 className="information__header">Zamówienia online</h4>
          <p>
            Lunch lub kolacja z dostawą pod same drzwi? Tak, to możliwe!
            Realizujemy dostawy naszych specjałów z karty oraz „obiadów dnia”.
            Na terenie miasta Sierpca dostawy realizowane są nieodpłatnie,
            doliczamy jedynie koszt za opakowanie. W przypadku zamówień poza
            strefę naszych darmowych dostaw zachęcamy do kontaktu
            telefonicznego. Wspólnie ustalimy możliwość dowozu zamówienia.
          </p>
          <Button
            style={{ marginTop: !isDesktop ? "15px" : "unset" }}
            label="Zamów online"
            onClick={() => window.location.href = 'https://koneser.s4honline.pl/'}
            size="small"
            type="primary"
          />
        </div>
        <div className="information__col">
          <h4 className="information__header">Godziny otwarcia</h4>
          {openingTimes.map(
            ({
              day,
              openTime,
              closeTime,
            }: {
              day: string;
              openTime: string;
              closeTime: string;
            }) => {
              return (
                <>
                  {isDesktop ? (
                    <p>{`${day}: ${openTime} - ${closeTime}`}</p>
                  ) : (
                    <p
                      style={{
                        width: "100%",
                        display: "flex",
                        borderBottom: "1px solid #E6E6E6",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{`${day}:`}</div>
                      <div>{`${openTime}-${closeTime}`}</div>
                    </p>
                  )}
                </>
              );
            }
          )}
        </div>
      </Container>
    </section>
  );
};
